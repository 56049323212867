html
  @include media-breakpoint-up(sm)
    font-size: 14px
  @include media-breakpoint-up(md)
    font-size: 16px
  @include media-breakpoint-up(lg)
    font-size: 18px

  body
    font-family: "Open Sans", sans-serif !important
    line-height: 1.6rem !important
    padding-top: $bodyPadding
  
  nav
    background-color: white
    border-bottom: 1px solid rgba(0, 0, 0, 0.2)

    a
      white-space: nowrap !important
      overflow: hidden
      color: black !important
      font-weight: 600
      text-decoration: none
      &:hover
        color: $primary !important
    .active
      span
        border-bottom: 2px solid

    #phone-link
      color: $primary !important

    button
      font-weight: 600 !important
    
    .ds-logo
      width: 100%
      max-height: 2rem
      object-fit: scale-down

    @include media-breakpoint-up(lg)
      #phone-link
        margin-left: 1rem
      .navbar-nav
        align-items: center !important

    @include media-breakpoint-down(md)
      .navbar-nav
        li
          margin-left: 1rem

    .nav-highlight
      color: $primary !important
      &:hover
        color: black !important

  main
    background: linear-gradient(180deg, rgba(255,255,255,0) calc( 100% - 10rem ), rgba(0,212,255,0.15) 100%)
    padding-bottom: 10rem
    h1, h2
      font-weight: 800

    .themed-header
      color: $primary !important

    h1
      font-size: 2.5rem

    h2
      font-size: 2rem

    .card
      margin: 1rem
      padding: 1rem
      border-radius: 1rem

      .card-title
        color: $primary
        font-weight: bold

      .card-img-top
        max-width: 50%
      .card-body
        a
          color: $bodyColor
          font-weight: bold

    .row
      margin-bottom: 4rem
      margin-top: 6rem
      @include media-breakpoint-down(lg)
        &:first-child
          margin-top: 2rem

    .contact-form
      & > *
        margin-bottom: 0.8rem
        background-color: $gray
    
    table
      overflow-x: auto
    
    .case-study
      padding: 0
      position: relative
      width: 100%
      height: 100%
      border-radius: 1rem
      border: 1px solid rgba(0, 0, 0, 0.125)
      @include media-breakpoint-down(md)
        padding: 1rem
        img:first-child
          display: none
        div
          padding: 1rem
          h2
            color: $primary
            font-size: 1.4rem
            font-weight: bold
          a
            color: black
            font-weight: bold
          img
            margin-top: 1rem
            width: 100%
        #folder-icon
          width: 35%
      @include media-breakpoint-up(md)
        img:first-child
          float: left
          clip-path: polygon(0% 0%, 100% 0%, 50% 100%, 0% 100%)
          border-radius: 1rem 0 0 1rem
          width: 50%
        div
          padding: 18% 2% 0 45%
          h2
            font-size: 1.4rem
            @include media-breakpoint-up(lg)
              font-size: 1.6rem
          img
            margin: 5% 0 0 -15%
            width: 80%
        #folder-icon
          position: absolute
          top: 18%
          left: 45%
          transform: translate(-50%, -50%)
          width: 15%

  footer
    padding-top: 5rem
    img
      max-width: 100%
    a
      color: black
      text-decoration: none
