.sidebar-col
	font-size: 80%
	.sticky-top
		padding-top: 4rem !important
	a
		text-decoration: none
	.toc
		margin-bottom: 2rem
	.demo-card
		padding: 2rem
	.feed
		text-align: center

article
	h2, h3, h4, h5, h6
		// Hack to deal with the headings being offset by the top nav
		padding-top: $bodyPadding + 2
		margin: -($bodyPadding) 0 1rem 0
	img
		width: 100%
		margin: 1rem 0 2rem 0
	.shadow
		border-radius: 6px
		box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.4) !important
	.post-tag
		font-size: 80%
		border: 1px solid
		border-radius: 5px
		padding: 0.25rem

	.article-list
		margin-top: 2rem
	a
		color: black
		font-weight: 600
	h3 > a
		text-decoration: none
		color: $primary
	p
		margin: 1rem 0rem 1.5rem 0rem
	.article-list-entry
		h1, h2, h3, h4, h5, h6
			padding-top: 0
			margin: 1rem 0 1rem 0
		&:not(:first-child)
			margin-top: 4rem

