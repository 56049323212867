html
  main
    .home-row
      h4
        @include media-breakpoint-up(lg)
          padding-right: 2rem
    #home-hero
      @include media-breakpoint-up(lg)
        width: 130%
        margin-left: -30%

    .customer-images
      img
        max-width: 100%
        padding: 1rem

    .card-row > *
      text-align: center
      margin: 2rem 0 1rem 0
      .card
        padding: 2rem 0 0 0
        height: 100%
      img
        margin-left: 50%
        transform: translate(-50%, 0)
