$bodyColor: #000000
$primary: #05a1c7
$secondary: #8DC63F
$gray: #F5F5F5
$navMargin: 6rem
$bodyPadding: 3.5rem
$table-striped-bg: rgba($primary, 0.1)

@import 'bootstrap/scss/bootstrap'

@import 'common'
@import 'article'
@import 'home'
@import 'falco'
@import 'about'
@import 'customers'
