.falco-table
    td:not(:first-child), th:not(:first-child)
        text-align: center
    thead
        border-bottom: 2px solid !important
        th
            font-size: 1rem
            font-weight: 600
    tbody
        tr
            td
                padding: 0.5rem 0 0.5rem 0
                &:first-child
                    font-weight: 600
                    padding-left: 1rem
                &:not(:first-child)
                    &:not(.text)
                        color: $primary
                        font-size: 2rem
                &[checked]::before
                    text-align: center
                    content: url("/assets/images/icons/check.svg")

.tier-cards
    .col-sm-12
        @include media-breakpoint-down(md)
            padding: 1.2rem
            &:nth-child(2n)
               margin-top: 2rem 
    .card-container
        position: relative
        background-color: $gray
        border: 1px solid lightgray
        padding: 1.2rem 1.2rem 1.2rem 2rem
        box-shadow: rgba(0, 0, 0, 0.176) 0px 8px 16px 0px
        #most-popular
            background-color: rgba(5, 161, 199, 0.2)
            position: absolute
            border: 1px solid lightgray
            top: -2rem
            left: 0
            width: 100%
            height: 2rem
            line-height: 2rem
            text-align: center
        em,b
            display: block
        em
            @include media-breakpoint-up(md)
                height: 5rem
        p
            @include media-breakpoint-up(md)
                height: 3rem
        ul
            list-style-image: url("/assets/images/icons/check.svg")
            li:not(:last-child)
                margin-bottom: 1rem
    .card-title
        font-size: 1.8rem
        font-weight: 600
        margin-bottom: 1rem
