.customer-card
    border-radius: 1rem
    padding: 0
    img:first-child
        max-height: 12rem
        object-fit: cover
        width: 100%
        border-radius: 1rem 1rem 0 0
    .card-title
        font-size: 1.2rem
        font-weight: 600
        color: $primary

#customers-intro-row
    p
        padding-top: 3rem
        line-height: 2rem
    #about-hero
        @include media-breakpoint-up(xl)
                margin-left: 4rem
