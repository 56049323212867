.about-header
    margin-top: 1rem
    padding-top: 1rem
    text-align: center
.about-subheader
    margin-top: 0rem
    padding-top: 0rem
    text-align: center

#intro-row
    p
        padding-top: 3rem
        line-height: 2rem
    #about-hero
        @include media-breakpoint-up(xl)
                margin-left: 6rem

#solution-row
    align-items: center
    div
        margin-bottom: 2rem
    img
        display: block
        margin: auto
        width: 50%
    p
        text-align: center

.blurb-row
    align-items: center
    @include media-breakpoint-down(sm)
        img
            display: block
            margin: auto
            width: 80%
            margin-bottom: 2rem
    a
        color: $primary
